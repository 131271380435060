import React, { useEffect, useState } from "react";
import { IoMdAdd, IoMdArrowBack } from "react-icons/io";
import axios from "../../lib/axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { FaCheck, FaPlus } from "react-icons/fa6";
import toast from "react-hot-toast";
import { IoClose } from "react-icons/io5";

import Document from "../../components/purchases/Document";
import Options from "../../components/purchases/Options";
import Delete from "../../components/Delete";
import { useSelector } from "react-redux";
import { digitsFaToEn } from "@persian-tools/persian-tools";
import { toEn } from "../../lib/toEn";

function PurchaseEdit() {
  const { id } = useParams();
  const inputs = {
    id: "",
    name: "",
    description: "",
    qtys: 0.0,
    qty_price: 0.0,
    total_price: 0.0,
    item_order: "",
  };
  const [supplier, setSupplier] = useState({});
  const [purchase, setPurchase] = useState({});
  const [items, setItems] = useState([inputs]);
  const [docs, setDocs] = useState([]);
  const [deleted, setDeleted] = useState([]);
  const deleteItem = useSelector((state) => state.delete.item);
  const paymentCreateForm = useSelector(
    (state) => state.purchase.paymentCreateForm
  );

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!paymentCreateForm) {
      getData();
    }
  }, [id, paymentCreateForm]);

  const getData = () => {
    axios
      .get(`/api/purchase/${id}/edit`)
      .then((data) => {
        setSupplier(data.supplier);
        if (data.items.length > 0) {
          setItems(data.items);
        } else {
          setItems([inputs]);
        }
        setDocs(data.docs);

        delete data.supplier;
        delete data.items;
        delete data.docs;
        setPurchase(data);
        console.log("wwwwwwwwwwwwwww", data);
      })
      .catch((error) => {
        toast.error(error.message);
        console.log(error);
      });
  };

  const updatePurchase = (index, value) =>
    setPurchase((prevValue) => ({ ...prevValue, [index]: value }));

  const editItem = (index, i, value) => {
    const updatedItems = items.map((item, j) => {
      if (j === index) {
        let total = 0.0;
        if (i === "qtys") {
          total = value * item.qty_price;
        } else if (i === "qty_price") {
          total = value * item.qtys;
        } else {
          total = item.total_price;
        }
        return { ...item, [i]: value, total_price: total };
      } else {
        return item;
      }
    });

    setItems(updatedItems);
  };

  const addItem = () => {
    setItems([...items, inputs]);
  };

  const removeItem = (index) => {
    if (items[index].id) {
      setDeleted([...deleted, items[index].id]);
    }
    const filters = items.filter((item, i) => i !== index);
    setItems(filters);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    console.log("ddddd", purchase);
    axios
      .put(`/api/purchase/${id}/update`, { ...purchase, items, deleted })
      .then((data) => {
        console.log(data);
        setLoading(false);
        if (data.status === 201) {
          const { purchase } = data;

          setItems(purchase.items);

          delete purchase.supplier;
          delete purchase.items;
          setPurchase(purchase);
          setDeleted([]);
          toast.success("ویرایش موفقانه انجام شد.");
        } else {
          toast.error(data.message);
        }
      })
      .catch((error) => {
        const { response } = error;
        setLoading(false);
        if (response.status === 422) {
          toast.error(response.data.message);
        } else {
          toast.error(error.message);
        }
      });
  };

  const handleItemChange = (index, e, i) => {
    const value = toEn(e);
    const regex = /^[0-9]*(\.[0-9]{0,5})?$/;
    console.log(value);
    if (regex.test(value)) {
      editItem(index, i, value);
    }
  };

  return (
    <div>
      <div className="flex bg-slate-50 border justify-between py-2 px-4 ">
        <h3>نمبر خریداری {id}</h3>
        <div className="flex gap-6">
          <Options purchase={purchase} edit={true} />
          <button onClick={addItem} className="flex hover:text-blue-400">
            <FaPlus className="mt-1 ml-1 font-bold" />
            اضافه کردن جنس
          </button>
          <button
            type="submit"
            form="submitForm"
            className="flex bg-sky-500 text-white rounded-sm py-1 px-2 hover:bg-sky-700 hover:font-semibold"
            disabled={loading ? true : false}
          >
            {loading ? (
              <AiOutlineLoading3Quarters className="mt-1 mx-2 animate-spin" />
            ) : (
              <FaCheck className="mt-1 mx-2" />
            )}
            ذخیره
          </button>
        </div>
      </div>
      <div className="flex justify-between p-4">
        <div className="flex flex-col gap-4 text-gray-500">
          <Link
            className="text-sky-500 font-bold text-2xl hover:underline"
            to={`/suppliers/${1}/edit`}
          >
            {supplier.name}
          </Link>
          <p>{supplier.address1}</p>
          <p>
            نمبر تماس: <span dir="ltr">{supplier.phone_number}</span>
          </p>
          <p>
            واتساب: <span dir="ltr">{supplier.whatsapp_number}</span>
          </p>
          <p>
            ایمیل:{" "}
            <a
              href="mailto:azim@momtaz.af"
              target="_blank"
              className="text-blue-500 "
            >
              {supplier.email}
            </a>
          </p>
        </div>
        <div className="text-gray-500">
          <table>
            <tr>
              <th className="bg-gray-50 border w-36">تاریخ خریداری</th>
              <td className="bg-gray-50 border w-60 p-2">
                <input
                  name="date"
                  type="date"
                  form="submitForm"
                  required
                  onChange={(e) => updatePurchase("date", e.target.value)}
                  value={purchase.date}
                  className="ring-1 ring-inset ring-gray-300  p-1 rounded-sm w-full"
                />
              </td>
            </tr>
            <tr>
              <th className="bg-gray-50 border w-36">تاریخ سر رسید</th>
              <td className="bg-gray-50 border w-60 p-2">
                <input
                  name="due_date"
                  type="date"
                  form="submitForm"
                  required
                  onChange={(e) => updatePurchase("due_date", e.target.value)}
                  value={purchase.due_date}
                  className="ring-1 ring-inset ring-gray-300  p-1 rounded-sm w-full"
                />
              </td>
            </tr>
            <tr>
              <th className=" border w-36">ثبت انبار گردیده</th>
              <td className=" border w-60 p-2">
                <select
                  name="stocked"
                  required
                  form="submitForm"
                  value={purchase.stocked}
                  disabled
                  onChange={(e) => updatePurchase("stocked", e.target.value)}
                  className="ring-1 ring-inset ring-gray-300  p-1 rounded-sm w-full bg-amber-600 text-white"
                >
                  <option value={0}>نخیر</option>
                  <option value={1}>بلی</option>
                </select>
              </td>
            </tr>
            <tr>
              <th className="bg-gray-50 border w-36">واحد پولی</th>
              <td className="bg-gray-50 border w-60 p-2">
                <select
                  name="currency"
                  required
                  form="submitForm"
                  onChange={(e) => updatePurchase("currency", e.target.value)}
                  value={purchase.currency}
                  className="ring-1 ring-inset ring-gray-300  p-1 rounded-sm w-full bg-white"
                >
                  <option value="دالر">دالر</option>
                  <option value="ین">ین</option>
                  <option value="افغانی">افغانی</option>
                </select>
              </td>
            </tr>
            <tr>
              <th className=" border w-36">۱ دالر به ؟</th>
              <td className=" border w-60 p-2">
                <input
                  name="currency_rate"
                  type="number"
                  form="submitForm"
                  step={0.01}
                  required
                  onChange={(e) =>
                    updatePurchase("currency_rate", e.target.value)
                  }
                  value={purchase.currency_rate}
                  className="ring-1 ring-inset ring-gray-300  p-1 rounded-sm w-full"
                />
              </td>
            </tr>
          </table>
        </div>
      </div>
      <form
        action=""
        id="submitForm"
        onSubmit={handleSubmit}
        method="post"
      ></form>
      <div className="p-4 text-gray-500">
        <table className="table-auto w-full">
          <thead>
            <tr>
              <th className="px-4 py-2 text-start border">
                نام جنس <span className="text-red-500">*</span>
              </th>
              <th className="px-4 py-2 text-start border"> توضیخات </th>
              <th className="px-4 py-2 text-start border">
                تعداد <span className="text-red-500">*</span>
              </th>
              <th className="px-4 py-2 text-start border">
                قیمت فی عدد <span className="text-red-500">*</span>
              </th>
              <th className="px-4 py-2 text-start border">قیمت مجموعی</th>
              <th className="px-4 py-2 text-start border"></th>
            </tr>
          </thead>
          <tbody>
            {items.map((row, index) => (
              <tr key={index} className="bg-gray-50">
                <td className=" px-4 py-2 border align-top ">
                  <input
                    name="name"
                    type="text"
                    required
                    form="submitForm"
                    value={row.name}
                    onChange={(e) => editItem(index, "name", e.target.value)}
                    className="flex items-start ring-1 ring-inset ring-gray-300  p-1 rounded-sm w-full"
                  />
                </td>
                <td className="px-4 py-2 border align-top">
                  <textarea
                    name="description"
                    rows={2}
                    form="submitForm"
                    value={row.description}
                    onChange={(e) =>
                      editItem(index, "description", e.target.value)
                    }
                    className="ring-1 ring-inset ring-gray-300  p-1 rounded-sm w-full"
                  />
                </td>
                <td className=" px-4 py-2 border align-top">
                  <input
                    name="qtys"
                    type="text"
                    form="submitForm"
                    step={0.01}
                    min={0.0}
                    pattern="^0*(\d+(\.\d{0,3})?|\.\d{1,3})$"
                    value={row.qtys}
                    required
                    onChange={(e) => handleItemChange(index, e, "qtys")}
                    className="flex items-start ring-1 ring-inset ring-gray-300  p-1 rounded-sm w-full"
                  />
                </td>
                <td className=" px-4 py-2 border align-top">
                  <input
                    name="qty_price"
                    type="text"
                    form="submitForm"
                    step={0.00001}
                    min={0.0}
                    pattern="^0*(\d+(\.\d{0,5})?|\.\d{1,5})$"
                    required
                    value={row.qty_price}
                    onChange={(e) => handleItemChange(index, e, "qty_price")}
                    className="flex items-start ring-1 ring-inset ring-gray-300  p-1 rounded-sm w-full"
                  />
                </td>
                <td className=" px-4 py-2 border align-top">
                  {row.total_price} {purchase.currency}
                </td>
                <td className="px-2 py-2 border align-top">
                  {items.length > 1 && (
                    <button onClick={() => removeItem(index)}>
                      <IoClose size={28} className="text-blue-500" />
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="p-4 text-gray-600">
        <table>
          <tr>
            <th className="bg-gray-50 border w-36">مجموع</th>
            <td className="bg-gray-50 border w-60 p-2">
              {purchase.total} {purchase.currency}
            </td>
          </tr>
          <tr>
            <th className=" border w-36">دریافت شد</th>
            <td className=" border w-60 p-2">
              {purchase.paid} {purchase.currency}
            </td>
          </tr>
          <tr>
            <th className="bg-gray-50 border w-36">بیلانس</th>
            <td className="bg-gray-50 border w-60 p-2">
              {purchase.balance} {purchase.currency}
            </td>
          </tr>
        </table>
      </div>
      <div className="p-4">
        <Document id={purchase.id} docs={docs} setDocs={setDocs} />
      </div>
      {deleteItem.id && <Delete />}
    </div>
  );
}

export default PurchaseEdit;

// [11, 12, 13, 14, 15, 16]

// clients_id[4, 6, 7, 9, 890]1, 2, 3, 4
