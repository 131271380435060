import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  createForm: false,
  editObj: null,
  report: false,
};

export const equitySlice = createSlice({
  name: "equity",
  initialState,
  reducers: {
    showCreateForm: (state) => {
      state.createForm = !state.createForm;
    },
    showReportForm: (state) => {
      state.report = !state.report;
    },
    setObj: (state, { payload }) => {
      state.editObj = payload;
    },
  },
  extraReducers: (builder) => {},
});

export const { showCreateForm, setObj, showReportForm } = equitySlice.actions;
export default equitySlice.reducer;
