import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import axios from "../../lib/axios";
import { IoClose } from "react-icons/io5";
import Select from "react-select";
import { useEffect } from "react";
import { showReportForm } from "../../redux/features/loanSlice";
import { toEn } from "../../lib/toEn";
import { FaCheck, FaDownload } from "react-icons/fa";

const ReportLoan = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const [lenders, setLenders] = useState([]);
  const inputs = {
    from_date: "",
    to_date: "",
    creditor: null,
    account_id: "",
    lender_id: "",
  };
  const [formData, setFormData] = useState(inputs);
  const [maxValue, setMaxValue] = useState(0);
  const [selected, setSelected] = useState(null);
  const [accounts, setAccounts] = useState([]);

  useEffect(() => {
    if (lenders.length <= 0) {
      getLenders();
    }
    getAccounts();
  }, []);

  const getLenders = () => {
    axios
      .get("/api/lenders/select2")
      .then((data) => {
        setLenders(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getAccounts = () => {
    axios
      .get("/api/accounts/select2")
      .then((data) => {
        setAccounts(data);
        console.log(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formData);
    setLoading(true);
    axios
      .post(
        "api/loans/report",
        { ...formData },
        {
          responseType: "blob",
        }
      )
      .then((response) => {
        setLoading(false);
        console.log(response.data);
        const url = window.URL.createObjectURL(new Blob([response]));
        console.log(url);

        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `loans-report.pdf`); //or any other extension
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        // toast.success("عملیات موفقانه انجام شد.");
        // dispatch(showReportForm());
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error.message);
        console.log("reeees", error);
      });
  };

  const close = () => {
    dispatch(showReportForm());
  };

  return (
    <>
      <div className="fixed inset-0 opacity-35 bg-black z-10"></div>
      <div className="fixed inset-0 flex justify-center items-center z-10">
        <div className="relative p-4 w-full max-w-3xl max-h-full">
          <div className="relative bg-white rounded-sm shadow dark:bg-gray-700">
            <div className="flex justify-between p-2 border-b text-gray-500 font-semibold ">
              <p>تهیه گذارش قروض</p>

              <button
                type="button"
                className="hover:text-gray-700 hover:font-bold"
                onClick={() => close()}
              >
                <IoClose size={18} />
              </button>
            </div>

            <form
              action=""
              onSubmit={handleSubmit}
              className="p-4 text-gray-500"
              id="createSaleForm"
            >
              <div className="flex gap-8 items-center mb-3">
                <label htmlFor="name" className="w-1/3 md:w-1/4">
                  طلبکار
                </label>
                <div className="flex gap-8 justify-start  w-2/3 md:w-2/4">
                  <label className="flex gap-2 border rounded-md py-1 px-4">
                    <input
                      type="radio"
                      name="creditor"
                      value={true}
                      onChange={(e) =>
                        setFormData((prev) => ({
                          ...prev,
                          creditor: true,
                        }))
                      }
                    />
                    بلی
                  </label>
                  <label className="flex gap-2 border rounded-md py-1 px-4">
                    <input
                      type="radio"
                      name="creditor"
                      value={false}
                      onChange={(e) =>
                        setFormData((prev) => ({
                          ...prev,
                          creditor: false,
                        }))
                      }
                    />
                    نخیر
                  </label>
                </div>
              </div>
              <div className="flex gap-8 items-center mb-3">
                <label htmlFor="name" className="w-1/3 md:w-1/4">
                  از تاریخ
                </label>
                <input
                  name="date"
                  type="date"
                  onChange={(e) => {
                    setFormData((prevValue) => ({
                      ...prevValue,
                      from_date: e.target.value,
                    }));
                  }}
                  value={formData.from_date}
                  className="ring-1 ring-inset ring-gray-300  p-2 rounded-sm w-2/3 md:w-2/4"
                />
              </div>
              <div className="flex gap-8 items-center mb-3">
                <label htmlFor="name" className="w-1/3 md:w-1/4">
                  تا تاریخ
                </label>
                <input
                  name="date"
                  type="date"
                  onChange={(e) => {
                    setFormData((prevValue) => ({
                      ...prevValue,
                      to_date: e.target.value,
                    }));
                  }}
                  value={formData.to_date}
                  className="ring-1 ring-inset ring-gray-300  p-2 rounded-sm w-2/3 md:w-2/4"
                />
              </div>
              <div className="flex gap-8 items-center mb-3">
                <label htmlFor="name" className="w-1/3 md:w-1/4">
                  قرض دهنده
                </label>
                <Select
                  className="ring-1 ring-inset ring-gray-300 w-2/3 md:w-2/4"
                  placeholder="انتخاب"
                  onChange={(e) => {
                    setFormData((prevValue) => ({
                      ...prevValue,
                      lender_id: e.value,
                    }));
                  }}
                  options={lenders}
                />
              </div>

              <div className="flex gap-8 items-center mb-3">
                <label htmlFor="name" className="w-1/3 md:w-1/4">
                  حساب
                </label>
                <Select
                  className="ring-1 ring-inset ring-gray-300 w-2/3 md:w-2/4"
                  placeholder="انتخاب"
                  onChange={(e) => {
                    setFormData((prevValue) => ({
                      ...prevValue,
                      account_id: e.value,
                    }));
                  }}
                  options={accounts.filter((row) =>
                    row.use_for.includes("قروض")
                  )}
                />
              </div>
            </form>

            <div className="flex  py-2 px-4 bg-gray-50 border-t justify-end">
              <button
                data-modal-hide="popup-modal"
                form="createSaleForm"
                type="subbmit"
                className="flex text-white bg-sky-500 hover:bg-sky-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 py-1 px-4 rounded-sm"
                disabled={loading ? true : false}
              >
                {loading ? (
                  <AiOutlineLoading3Quarters className="mt-1 mx-2 animate-spin" />
                ) : (
                  <FaDownload className="mt-1 mx-2" />
                )}
                دانلود
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReportLoan;
