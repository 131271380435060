import React, { useEffect, useState } from "react";
import axios from "../../lib/axios";
import { toEn } from "../../lib/toEn";
import { useParams } from "react-router-dom";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { FaCheck } from "react-icons/fa6";
import toast from "react-hot-toast";

import Options from "../../components/preparations/Options";
import Delete from "../../components/Delete";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import Material from "../../components/preparations/Material";
import { TbIndentDecrease } from "react-icons/tb";
import Item from "../../components/preparations/Item";
import {
  MdDelete,
  MdDrafts,
  MdExpandLess,
  MdExpandMore,
  MdInventory,
} from "react-icons/md";
import {
  setDistributeId,
  showDistributeCreateForm,
} from "../../redux/features/distributeSlice";
import { setDelete } from "../../redux/features/deleteSlice";
import { FaPlusCircle } from "react-icons/fa";

function PreparationEdit() {
  const { id } = useParams();
  const formInput = {
    preparation_id: id,
    color_id: "",
    qtys: "",
  };
  const inputs = {
    id: "",
    name: "",
    description: "",
    qtys: 0.0,
    qty_price: 0.0,
    total_price: 0.0,
    item_order: "",
  };
  const [preparation, setPreparation] = useState({});
  const [items, setItems] = useState([]);
  const [openItem, setOpenItem] = useState("");
  const [formData, setFormData] = useState(formInput);
  const [existQtys, setExistQtys] = useState(0);
  const deleteItem = useSelector((state) => state.delete.item);

  const distributeCreateForm = useSelector(
    (state) => state.distribute.createForm
  );

  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [codes, setCodes] = useState([]);
  const [tmpCodes, setTmpCodes] = useState([]);
  const [codeId, setCodeId] = useState("");
  const [sizes, setSizes] = useState([]);
  const [colors, setColors] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!distributeCreateForm && !deleteItem.id) {
      getData();
    }
  }, [distributeCreateForm, deleteItem]);
  useEffect(() => {
    getColors();
    getCategories();
    getSizes();
  }, [id]);

  const getData = () => {
    axios
      .get(`/api/preparation/${id}/edit`)
      .then(({ data }) => {
        setItems(data.items);
        delete data.items;
        setPreparation(data);
        setCodeId(data.product_code_id);
        getCodes(data.product_category_id);

        console.log("wwwwwwwwwwwwwww", data);
      })
      .catch((error) => {
        // toast.error(error.message);
        console.log(error);
      });
  };

  const getCategories = () => {
    axios.get(`/api/settings/product/category/select2`).then((data) => {
      setCategories(data);
    });
  };
  const getCodes = (id) => {
    axios.get(`/api/settings/code/select2`).then((data) => {
      setCodes(data);
      const tmp = data.filter((row) => row.product_category_id === id);
      console.log("working heree" + tmp);
      setTmpCodes(tmp);
    });
  };
  const getColors = () => {
    axios.get(`/api/settings/colors/select2`).then((data) => {
      setColors(data);
    });
  };
  const getSizes = () => {
    axios.get(`/api/settings/sizes/select2`).then((data) => {
      setSizes(data);
    });
  };

  const submitPreparation = (e) => {
    e.preventDefault();
    setLoading(true);
    axios
      .put(`/api/preparation/${id}/update`, { ...preparation })
      .then(({ data }) => {
        setItems(data.items);
        delete data.items;
        setPreparation(data);
        setCodeId(data.product_code_id);
        toast.success("عملیات موفقانه انجام شد.");
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        catchHandle(error);
      });
  };

  const submitForm = (e) => {
    e.preventDefault();
    axios
      .post(`/api/preparation-item/create`, { ...formData })
      .then((data) => {
        if (data.status === 409) {
          toast.error("ریکارد با ان مشخصه موجود است.");
        } else {
          setItems([...items, data]);
          setOpenItem(data.id);
          setFormData(formInput);
          toast.success("عملیات موفقانه انجام شد.");
        }
      })
      .catch((error) => catchHandle(error));
  };

  const changeStatus = () => {
    axios
      .get(`/api/preparation/${id}/finalize`)
      .then((data) => {
        setPreparation((prv) => ({ ...prv, finalized: !prv.finalized }));
        toast.success("عملیات موفقانه انجام شد.");
      })
      .catch((error) => catchHandle(error));
  };

  const handlePreparationChange = (e, index) => {
    const value = toEn(e);

    const regex = /^[0-9]*(\.[0-9]{0,3})?$/;
    if (regex.test(value)) {
      setFormData((prev) => ({
        ...prev,
        [index]: value,
      }));
    }
  };

  const catchHandle = (error) => {
    const { response } = error;
    if (response.status === 422) {
      toast.error(response.data.message);
    } else {
      toast.error(error.message);
    }
  };

  const changeCurrent = (id) => {
    setOpenItem(id);
  };

  return (
    <div>
      <div className="flex bg-slate-50 border justify-between py-2 px-4 ">
        <h3>نمبر تهیه {id}</h3>
        <div className="flex gap-6">
          <Options product={preparation} edit={true} />

          {preparation.finalized ? (
            <button
              onClick={changeStatus}
              className="flex bg-gray-500 text-white rounded-sm py-1 px-2 hover:bg-gray-700 hover:font-semibold"
            >
              <MdDrafts className="mt-1 mx-2" />
              تبدیل به پیشنویس
            </button>
          ) : (
            <>
              <button
                type="submit"
                form="submitForm"
                className="flex bg-sky-500 text-white rounded-sm py-1 px-2 hover:bg-sky-700 hover:font-semibold"
                disabled={loading ? true : false}
              >
                {loading ? (
                  <AiOutlineLoading3Quarters className="mt-1 mx-2 animate-spin" />
                ) : (
                  <FaPlusCircle className="mt-1 mx-2" />
                )}
                دخیره
              </button>
              <button
                onClick={changeStatus}
                className="flex bg-gray-500 text-white rounded-sm py-1 px-2 hover:bg-gray-700 hover:font-semibold"
              >
                <FaCheck className="mt-1 mx-2" />
                نهای گردد
              </button>
            </>
          )}
        </div>
      </div>
      <div className="p-4">
        <div className="flex justify-between mb-4">
          <form onSubmit={submitPreparation} method="post" id="submitForm">
            <table>
              <tr>
                <th className="bg-gray-50 border w-44">
                  نوع محصول <span className="text-red-500">*</span>
                </th>
                <td className="bg-gray-50 border w-60 p-2">
                  {categories && (
                    <Select
                      className="ring-1 ring-inset ring-gray-300  "
                      placeholder="انتخاب"
                      required
                      onChange={(e) => {
                        setPreparation((prevValue) => ({
                          ...prevValue,
                          product_category_id: e.value,
                        }));
                        setTmpCodes(
                          codes.filter(
                            (row) => row.product_category_id === e.value
                          )
                        );
                        setCodeId(null);
                      }}
                      isDisabled={preparation.finalized}
                      options={categories}
                      value={categories.find(
                        (row) => row.value === preparation.product_category_id
                      )}
                    />
                  )}
                </td>
              </tr>
              <tr>
                <th className=" border w-44">
                  کد <span className="text-red-500">*</span>
                </th>
                <td className=" border w-60 p-2">
                  {codes && (
                    <Select
                      className="ring-1 ring-inset ring-gray-300 "
                      placeholder="انتخاب"
                      required
                      onChange={(e) => {
                        setPreparation((prevValue) => ({
                          ...prevValue,
                          product_code_id: e.value,
                        }));
                        setCodeId(e.value);
                      }}
                      isDisabled={preparation.finalized}
                      options={tmpCodes}
                      value={
                        codeId
                          ? tmpCodes.find((row) => row.value === codeId)
                          : null
                      }
                    />
                  )}
                </td>
              </tr>
              <tr>
                <th className="bg-gray-50 border w-44">
                  نرخ 1 دالر به افغانی <span className="text-red-500">*</span>
                </th>
                <td className="bg-gray-50 border w-60 p-2">
                  <input
                    name="currency_rate"
                    type="number"
                    required
                    onChange={(e) =>
                      setPreparation((prev) => ({
                        ...prev,
                        currency_rate: e.target.value,
                      }))
                    }
                    value={preparation.currency_rate}
                    placeholder="71.20"
                    className="flex items-start ring-1 ring-inset ring-gray-300  p-1 rounded-sm w-full"
                  />
                </td>
              </tr>
              <tr>
                <th className="bg-gray-50 border w-44">
                  نرخ 1 دالر به ین <span className="text-red-500">*</span>
                </th>
                <td className="bg-gray-50 border w-60 p-2">
                  <input
                    name="currency_rate_yuan"
                    type="number"
                    required
                    step={0.01}
                    onChange={(e) =>
                      setPreparation((prev) => ({
                        ...prev,
                        currency_rate_yuan: e.target.value,
                      }))
                    }
                    value={preparation.currency_rate_yuan}
                    placeholder="8.20"
                    className="flex items-start ring-1 ring-inset ring-gray-300  p-1 rounded-sm w-full"
                  />
                </td>
              </tr>
              <tr>
                <th className=" border w-44">
                  تاریخ <span className="text-red-500">*</span>
                </th>
                <td className=" border w-60 p-2">
                  <input
                    name="code"
                    type="date"
                    required
                    form="submitForm"
                    value={preparation.date}
                    disabled={preparation.finalized}
                    onChange={(e) =>
                      setPreparation((prev) => ({
                        ...prev,
                        date: e.target.value,
                      }))
                    }
                    className="flex items-start ring-1 ring-inset ring-gray-300  p-1 rounded-sm w-full"
                  />
                </td>
              </tr>
            </table>
          </form>
          <form
            method="post"
            onSubmit={submitForm}
            className=" mt-4 mb-12 text-gray-500"
          >
            <table className="table-auto">
              <thead>
                <tr>
                  <th className="px-4 py-2 text-start border">
                    رنگ <span className="text-red-500">*</span>
                  </th>

                  <th className="px-4 py-2 text-start border">
                    تعداد <span className="text-red-500">*</span>
                  </th>
                  <th className="px-4 py-2 text-start border"></th>
                </tr>
              </thead>
              <tbody>
                <tr className="bg-gray-50">
                  <td className=" px-4 py-2 border align-top ">
                    {colors && (
                      <Select
                        className="ring-1 ring-inset ring-gray-300 "
                        placeholder="انتخاب"
                        required
                        onChange={(e) =>
                          setFormData((prevValue) => ({
                            ...prevValue,
                            color_id: e.value,
                          }))
                        }
                        isDisabled={preparation.finalized}
                        options={colors}
                        value={
                          formData.color_id
                            ? colors.find(
                                (row) => row.value === formData.color_id
                              )
                            : null
                        }
                      />
                    )}
                  </td>

                  <td className=" px-4 py-2 border align-top ">
                    <input
                      name="qtys"
                      type="text"
                      required={true}
                      value={formData.qtys}
                      disabled={preparation.finalized}
                      onChange={(e) => handlePreparationChange(e, "qtys")}
                      className="flex items-start ring-1 ring-inset ring-gray-300  p-1 rounded-sm w-full"
                    />
                  </td>
                  <td className=" px-4 py-2 border align-top ">
                    {!preparation.finalized && (
                      <button className="bg-green-500 rounded-sm px-2 py-1 text-white hover:bg-green-700 ">
                        اضافه کردن ریکارد
                      </button>
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </form>
        </div>
        {items.map((row, index) => (
          <div className="border rounded-t-sm">
            <div className="flex justify-between bg-gray-100 border-b py-2 px-4">
              <h3>رنگ -- {row.cname}</h3>

              <div className="flex gap-6">
                {!preparation.finalized && (
                  <>
                    <button
                      className="flex px-4 py-1 text-white bg-red-400 hover:bg-red-700"
                      onClick={() =>
                        dispatch(
                          setDelete({
                            item: row,
                            url: `/api/preparation-item/${row.id}/delete`,
                          })
                        )
                      }
                    >
                      <MdDelete className="ml-2 mt-1" size={18} />
                      حذف
                    </button>
                    <button
                      onClick={() => {
                        dispatch(showDistributeCreateForm());
                        dispatch(setDistributeId(row.id));
                      }}
                      className="flex px-4 py-1 text-gray-800 bg-gray-300 hover:bg-white"
                    >
                      <MdInventory className="ml-2 mt-1" size={18} />
                      ثبت توزیع
                    </button>
                  </>
                )}
                <button
                  onClick={() => changeCurrent(row.id)}
                  className=" text-sky-500 hover:text-sky-800"
                >
                  {row.id !== openItem && <MdExpandMore size={28} />}
                </button>
              </div>
            </div>
            {row.id === openItem && (
              <Item
                item={row}
                setItems={setItems}
                finalized={preparation.finalized}
                currency_rate={preparation.currency_rate}
                currency_rate_yuan={preparation.currency_rate_yuan}
              />
            )}
          </div>
        ))}
      </div>

      {deleteItem.id && <Delete />}
    </div>
  );
}

export default PreparationEdit;
