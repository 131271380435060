import React, { useEffect, useState } from "react";
import { IoIosAddCircle } from "react-icons/io";
import Options from "../../components/loans/Options";
import { useDispatch, useSelector } from "react-redux";
import axios from "../../lib/axios";
import { Link } from "react-router-dom";
import Delete from "../../components/Delete";
import Select from "react-select";
import { showCreateForm } from "../../redux/features/loanSlice";
import CreateLoan from "../../components/loans/CreateLoan";

function LoanList() {
  const createForm = useSelector((state) => state.loan.createForm);
  const editObj = useSelector((state) => state.deposit.editObj);
  const [loans, setLoans] = useState({});
  const deleteItem = useSelector((state) => state.delete.item);
  const [lenders, setLenders] = useState([]);
  const [customerId, setCustomerId] = useState([]);
  const [categories, setCategories] = useState([
    { name: "همه", active: true, en: "all" },
    { name: "تصویه شده ها", active: false, en: "balance" },
    { name: "طلبکار", active: false, en: "creditor" },
  ]);
  const [search, setSearch] = useState("");
  const [records, setRecords] = useState(10);
  const [url, setUrl] = useState(`/api/loans`);

  const dispatch = useDispatch();

  useEffect(() => {
    axios
      .get("/api/lenders/select2")
      .then((data) => {
        setLenders(data);
        console.log(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  useEffect(() => {
    if (!deleteItem.id && !createForm && !editObj) {
      getLoans();
    }
  }, [categories, deleteItem, records, url, customerId, createForm, editObj]);
  useEffect(() => {
    let delaySearch;
    const handleSearchDelay = () => {
      delaySearch = setTimeout(() => {
        console.log(`Performing search for: ${search}`);
        getLoans();
      }, 800);
    };
    const handleKeyUp = () => {
      if (delaySearch) {
        clearTimeout(delaySearch);
      }
      handleSearchDelay();
    };
    handleKeyUp();
    return () => {
      clearTimeout(delaySearch);
    };
  }, [search]);

  const getLoans = () => {
    axios
      .post(url, {
        category: categories.find((row) => row.active === true).en,
        search,
        records,
        customerId,
      })
      .then((data) => {
        setLoans(data);
        console.log(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const updateCategory = (index) => {
    setCategories((prevCategories) => {
      const updatedCategories = prevCategories.map((row, i) => {
        if (index === i) {
          return { ...row, active: true };
        }
        return { ...row, active: false };
      });

      return updatedCategories;
    });
  };

  return (
    <div>
      <div className="flex flex-col sm:flex-row bg-slate-50 border justify-between py-2 px-4">
        <h3>لیست قرضه ها</h3>
        <div className="flex gap-6 ">
          {categories.map((row, index) => (
            <button
              key={index}
              onClick={() => updateCategory(index)}
              className={` rounded-sm  border border-blue-300 px-2 ${
                row.active
                  ? "bg-blue-300 text-gray-700"
                  : "text-blue-300 hover:text-blue-500 hover:border-blue-500 hover:bg-gray-100"
              }`}
              disabled={row.active ? true : false}
            >
              {row.name}
            </button>
          ))}
          <button
            onClick={() => dispatch(showCreateForm())}
            className="flex bg-sky-500 text-white rounded-sm py-1 px-2 hover:bg-sky-700 hover:font-semibold"
          >
            <IoIosAddCircle className="ml-1" size={22} />
            اضافه کردن
          </button>
        </div>
      </div>
      <div className="p-4">
        <div className="flex gap-4 mt-8">
          <select
            onChange={(e) => setRecords(e.target.value)}
            className="ring-1 ring-inset ring-gray-300  mb-3 px-4 py-2 rounded-md"
          >
            <option className="p-0 m-0" value={10}>
              10
            </option>
            <option className="p-0 m-0" value={20}>
              20
            </option>
            <option className="p-0 m-0" value={50}>
              50
            </option>
            <option className="p-0 m-0" value={100}>
              100
            </option>
            <option className="p-0 m-0" value={500}>
              500
            </option>
          </select>
          <Select
            placeholder="قرضه دهنده"
            required
            onChange={(e) => setCustomerId(e ? e.value : null)}
            isClearable={true}
            options={lenders}
            styles={{
              control: (provided) => ({
                ...provided,
                padding: "0.2rem", // Adjust the padding value as needed
              }),
            }}
            className="w-60 z-0"
          />
          <input
            type="text"
            className="ring-1 ring-inset ring-gray-300  mb-3 px-4 py-2 rounded-md"
            value={search}
            placeholder="جستجو..."
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        <table className="table-auto w-full">
          <thead>
            <tr className="border-y">
              <th className="px-4 py-2 text-start">#</th>
              <th className="px-4 py-2 text-start">نام قرضه دهنده</th>
              <th className="px-4 py-2 text-start">تاریخ</th>
              <th className="px-4 py-2 text-start">مقدار</th>
              <th className="px-4 py-2 text-start">بیلانس</th>
              <th className="px-4 py-2 text-start">عملیه ها</th>
            </tr>
          </thead>
          <tbody>
            {loans.data &&
              loans?.data.map((row, index) => (
                <tr
                  key={index}
                  className={`${
                    index % 2 === 0 ? "bg-gray-100" : "bg-white"
                  } border`}
                >
                  <td className="px-4 py-2">{row.id}</td>
                  <td className="px-4 py-2">{row.name}</td>
                  <td className={`px-4 py-2 `}>{row.date}</td>
                  <td className="px-4 py-2">
                    {row.amount} {row.type}
                  </td>
                  <td className="px-4 py-2">
                    {parseFloat(row.balance).toFixed(3)} {row.type}
                  </td>
                  <td className="px-4 py-2">
                    <Options loan={row} />
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        <div className="flex gap-4 justify-end p-4">
          {loans.links &&
            loans.links.length > 3 &&
            loans.links.map((row, index) => (
              <button
                disabled={row.active || row.url === null}
                onClick={() => setUrl(row.url)}
                className={`border py-1 px-3 ${
                  row.active ? "bg-blue-500 text-white" : "hover:bg-gray-300"
                }`}
              >
                {row.label}
              </button>
            ))}
          {createForm && <CreateLoan />}
          {deleteItem.id && <Delete />}
        </div>
      </div>
    </div>
  );
}

export default LoanList;
