import React from "react";
import "./App.css";
import { useLocation, Routes, Route } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";

import Dashboard from "./pages/Dashboard";
import Login from "./pages/auth/Login";
import PrivateRoutes from "./routes/PrivateRoutes";
import ForgetPassword from "./pages/auth/forget-password";

import Nav from "./components/Nav";
import UserList from "./pages/users/UserList";
import RoleList from "./pages/roles/RoleList";
import RoleEdit from "./pages/roles/RoleEdit";
import RoleCreate from "./pages/roles/RoleCreate";
import UserCreate from "./pages/users/UserCreate";
import UserEdit from "./pages/users/UserEdit";
import SupplierList from "./pages/suppliers/SupplierList";
import SupplierCreate from "./pages/suppliers/SupplierCreate";
import SupplierEdit from "./pages/suppliers/SupplierEdit";
import PurchaseList from "./pages/purchases/PurchaseList";
import CreatePurchase from "./components/purchases/CreatePurchase";
import PurchaseEdit from "./pages/purchases/PurchaseEdit";
import PaymentList from "./pages/purchases/Payments/PaymentList";
import SPaymentList from "./pages/sales/payments/PaymentList";
import ColorList from "./pages/settings/colors/ColorList";
import SizeList from "./pages/settings/sizes/SizeList";
import TeamList from "./pages/settings/teams/TeamList";
import CategoryList from "./pages/settings/categories/CategoryList";
import MaterialList from "./pages/materials/MaterialList";
import Material from "./pages/purchases/Material";
import MCategoryList from "./pages/settings/categories/MCategoryList";
import ProductList from "./pages/products/ProductList";
import CustomerList from "./pages/customers/CustomerList";
import CustomerCreate from "./pages/customers/CustomerCreate";
import CustomerEdit from "./pages/customers/CustomerEdit";
import SaleList from "./pages/sales/SaleList";
import SaleEdit from "./pages/sales/SaleEdit";
import AccountList from "./pages/accounts/AccountList";
import ECategoryList from "./pages/settings/categories/ECategoryList";
import EmployeeList from "./pages/settings/employees/EmployeeList";
import ExpenseList from "./pages/expenses/ExpenseList";
import ExpenseCreate from "./pages/expenses/ExpenseCreate";
import ExpenseEdit from "./pages/expenses/ExpenseEdit";
import CodeList from "./pages/settings/code/CodeList";
import PreparationList from "./pages/preparations/PreparationList";
import PreparationEdit from "./pages/preparations/PreparationEdit";
import DistributeList from "./pages/distributes/DistributeList";
import Setting from "./pages/settings/settings/Setting";
import { useSelector } from "react-redux";
import NotFound from "./NotFound";
import QuoteList from "./pages/quotes/QuoteList";
import QuoteEdit from "./pages/quotes/QuoteEdit";
import DepositList from "./pages/deposits/DepositList";
import JournalItems from "./pages/accounting/JournalItems";
import LenderList from "./pages/lenders/LenderList";
import LenderCreate from "./pages/lenders/LenderCreate";
import LenderEdit from "./pages/lenders/LenderEdit";
import LoanList from "./pages/loans/LoanList";
import LoanEdit from "./pages/loans/LoanEdit";
import MinList from "./pages/materials/MinList";
import EquityList from "./pages/equities/EquityList";
import DistributePayment from "./pages/distributes/DistributePayment";

const App = () => {
  const location = useLocation();
  const authToken = useSelector((state) => state.auth.authToken);

  return (
    <>
      <Toaster />
      {location.pathname !== "/login" &&
        location.pathname !== "/forget-password" &&
        authToken && <Nav />}
      <Routes>
        <Route element={<PrivateRoutes />}>
          <Route element={<Dashboard />} path="/" />
          <Route element={<SupplierList />} path="/suppliers" />
          <Route element={<SupplierCreate />} path="/suppliers/create" />
          <Route element={<SupplierEdit />} path="/suppliers/:id/edit" />
          <Route element={<PurchaseList />} path="purchases" />
          <Route element={<PurchaseEdit />} path="/purchases/:id/edit" />
          <Route element={<PaymentList />} path="purchases/payments" />
          <Route element={<Material />} path="purchase-materials/:id" />
          <Route element={<MaterialList />} path="materials" />
          <Route element={<MinList />} path="materials-min" />
          <Route element={<PreparationList />} path="preparations" />
          <Route element={<PreparationEdit />} path="preparations/:id/edit" />
          <Route element={<DistributeList />} path="distributes" />
          <Route
            element={<DistributePayment />}
            path="distributes/:id/payments"
          />
          <Route element={<ProductList />} path="products" />
          <Route element={<CustomerList />} path="/customers" />
          <Route element={<CustomerCreate />} path="/customers/create" />
          <Route element={<CustomerEdit />} path="/customers/:id/edit" />
          <Route element={<SaleList />} path="/sales" />
          <Route element={<SaleEdit />} path="/sales/:id/edit" />
          <Route element={<DepositList />} path="/customer/deposits" />
          <Route element={<SPaymentList />} path="sales/payments" />
          <Route element={<AccountList />} path="/accounts" />
          <Route element={<ExpenseList />} path="/expenses" />
          <Route element={<ExpenseCreate />} path="/expenses/create" />
          <Route element={<ExpenseEdit />} path="/expenses/:id/edit" />
          <Route element={<QuoteList />} path="/quotes" />
          <Route element={<QuoteEdit />} path="/quotes/:id/edit" />
          <Route element={<LenderCreate />} path="/lenders/create" />
          <Route element={<LenderEdit />} path="/lenders/:id/edit" />
          <Route element={<LenderList />} path="/lenders" />
          <Route element={<LoanEdit />} path="/loans/:id/edit" />
          <Route element={<LoanList />} path="/loans" />
          <Route element={<EquityList />} path="/equities" />

          <Route element={<UserList />} path="/users" />
          <Route element={<UserCreate />} path="/users/create" />
          <Route element={<UserEdit />} path="/users/:id" />
          <Route element={<RoleList />} path="/roles" />
          <Route element={<RoleEdit />} path="/roles/:id" />
          <Route element={<RoleCreate />} path="/roles/create" />

          <Route element={<ColorList />} path="/settings/colors" />
          <Route element={<SizeList />} path="/settings/sizes" />
          <Route element={<TeamList />} path="/settings/teams" />
          <Route element={<EmployeeList />} path="/settings/employees" />
          <Route element={<CategoryList />} path="/settings/categories" />
          <Route element={<CodeList />} path="/settings/codes" />
          <Route
            element={<MCategoryList />}
            path="/settings/material-categories"
          />
          <Route element={<Setting />} path="/settings/setting" />
          <Route
            element={<ECategoryList />}
            path="/settings/expense-categories"
          />
          <Route element={<JournalItems />} path="/accounting/journal-items" />
          <Route element={<NotFound />} path="/*" />
        </Route>
        <Route element={<Login />} path="/login" />
        <Route element={<ForgetPassword />} path="/forget-password" />
      </Routes>
    </>
  );
};

export default App;
