import React, { useEffect, useState } from "react";
import { IoIosAdd, IoIosAddCircle } from "react-icons/io";
import Options from "../../components/materials/Options";
import { useDispatch, useSelector } from "react-redux";
import axios from "../../lib/axios";
import { Link } from "react-router-dom";
import Delete from "../../components/Delete";
import { showCreateForm } from "../../redux/features/materialSlice";
import EditMaterial from "../../components/materials/EditMaterial";
import Select from "react-select";
import { debounce } from "lodash";
import toast from "react-hot-toast";

function MinList() {
  const [materials, setMaterials] = useState([]);
  const [url, setUrl] = useState(`/api/materials-min`);

  const dispatch = useDispatch();

  useEffect(() => {
    getMaterials();
  }, []);

  const getMaterials = () => {
    axios
      .get(url)
      .then((data) => {
        setMaterials(data);
        console.log(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const submitInput = (index) => {
    axios
      .post("/api/materials-min-add", {
        ...materials[index],
      })
      .then((data) => {
        console.log(data);
        toast.success("ریکارت موفقانه ثبت گردید.");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      <div className="flex flex-col sm:flex-row bg-slate-50 border justify-between py-2 px-4">
        <h3>سطح سفارش مجدد</h3>
      </div>
      <div className="p-4">
        <table className="table-auto w-full">
          <thead>
            <tr className="border-y">
              <th className="px-4 py-2 text-start">کتگوری</th>
              <th className="px-4 py-2 text-start">رنگ</th>
              <th className="px-4 py-2 text-start">موجودی</th>
              <th className="px-4 py-2 text-start">سطح هشدار</th>
            </tr>
          </thead>
          <tbody>
            {materials.map((row, index) => (
              <tr
                key={index}
                className={`${
                  index % 2 === 0 ? "bg-gray-100" : "bg-white"
                } border`}
              >
                <td className="px-4 py-2">{row.mname}</td>
                <td className="px-4 py-2">{row.name}</td>

                <td
                  className={`px-4 py-2 ${
                    parseInt(row.exist_qtys) < parseInt(row.min_qtys)
                      ? "text-red-500"
                      : ""
                  }`}
                >
                  {row.exist_qtys} {row.unit}
                </td>
                <td className="px-4 py-2 flex justify-start">
                  {/* <Options material={row} />{" "} */}
                  <input
                    type="number"
                    className="ring-1 ring-inset ring-gray-300 m-0 px-4 py-1 rounded-r-md"
                    value={row.min_qtys}
                    placeholder="0.00"
                    onChange={(e) =>
                      setMaterials((prv) =>
                        prv.map((item, i) =>
                          i === index
                            ? { ...item, min_qtys: e.target.value }
                            : item
                        )
                      )
                    }
                  />
                  <button
                    onClick={() => submitInput(index)}
                    className=" p-1  bg-green-500 text-white rounded-l-md"
                  >
                    <IoIosAdd size={22} />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="flex gap-4 justify-end p-4"></div>
      </div>
    </div>
  );
}

export default MinList;
