import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import axios from "../../lib/axios";
import { IoClose } from "react-icons/io5";
import Select from "react-select";
import { useEffect } from "react";
import { showReportForm } from "../../redux/features/distributeSlice";
import { FaCheck, FaDownload } from "react-icons/fa";

const ReportDistribute = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const inputs = {
    from: "",
    to: "",
    color_id: "",
    tailor_team_id: "",
    product_category_id: "",
    product_code_id: "",
    stocked: "",
    paid: "",
    bill_number: "",
  };
  const [formData, setFormData] = useState(inputs);
  const [teams, setTeams] = useState([]);
  const [categories, setCategories] = useState([]);
  const [codes, setCodes] = useState([]);
  const [tmpCodes, setTmpCodes] = useState([]);
  const [colors, setColors] = useState([]);

  useEffect(() => {
    getTeams();
    getColors();
    getCategories();
    getCodes();
  }, []);

  const getTeams = () => {
    axios
      .get("/api/settings/team/select2")
      .then((data) => {
        setTeams(data);
        console.log(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getCategories = () => {
    axios.get(`/api/settings/product/category/select2`).then((data) => {
      setCategories(data);
    });
  };
  const getCodes = () => {
    axios.get(`/api/settings/code/select2`).then((data) => {
      setCodes(data);
    });
  };
  const getColors = () => {
    axios.get(`/api/settings/colors/select2`).then((data) => {
      setColors(data);
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formData);
    setLoading(true);
    axios
      .post(
        "api/distributes/report",
        { ...formData },
        {
          responseType: "blob",
        }
      )
      .then((response) => {
        setLoading(false);
        console.log(response.data);
        const url = window.URL.createObjectURL(new Blob([response]));
        console.log(url);

        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `distributes-report.pdf`); //or any other extension
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        // toast.success("عملیات موفقانه انجام شد.");
        // dispatch(showReportForm());
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error.message);
        console.log("reeees", error);
      });
  };
  return (
    <>
      <div className="fixed inset-0 opacity-35 bg-black z-10"></div>
      <div className="fixed inset-0 flex justify-center items-center z-10">
        <div className="relative p-4 w-full max-w-3xl max-h-full">
          <div className="relative bg-white rounded-sm shadow dark:bg-gray-700">
            <div className="flex justify-between p-2 border-b text-gray-500 font-semibold ">
              <p>فورم گذارش دهی توزیع</p>

              <button
                type="button"
                className="hover:text-gray-700 hover:font-bold"
                onClick={() => dispatch(showReportForm())}
              >
                <IoClose size={18} />
              </button>
            </div>

            <form
              action=""
              onSubmit={handleSubmit}
              className="p-4 text-gray-500"
              id="createPurchaseForm"
            >
              <div className="flex gap-8 items-center mb-3">
                <label htmlFor="from" className="w-1/3 md:w-1/4">
                  از تاریخ
                </label>
                <input
                  name="from"
                  type="date"
                  onChange={(e) =>
                    setFormData((prevValue) => ({
                      ...prevValue,
                      from: e.target.value,
                    }))
                  }
                  value={formData.from}
                  className="ring-1 ring-inset ring-gray-300  p-2 rounded-sm w-2/3 md:w-2/4"
                />
              </div>
              <div className="flex gap-8 items-center mb-3">
                <label htmlFor="to" className="w-1/3 md:w-1/4">
                  تا به تاریخ
                </label>
                <input
                  name="to"
                  type="date"
                  onChange={(e) =>
                    setFormData((prevValue) => ({
                      ...prevValue,
                      to: e.target.value,
                    }))
                  }
                  value={formData.to}
                  className="ring-1 ring-inset ring-gray-300  p-2 rounded-sm w-2/3 md:w-2/4"
                />
              </div>
              <div className="flex gap-8 items-center mb-3">
                <label htmlFor="name" className="w-1/3 md:w-1/4">
                  تیم خیاطی
                </label>
                <Select
                  className="ring-1 ring-inset ring-gray-300 w-2/3 md:w-2/4"
                  placeholder="انتخاب"
                  onChange={(e) => {
                    setFormData((prevValue) => ({
                      ...prevValue,
                      tailor_team_id: e.value,
                    }));
                  }}
                  options={teams}
                />
              </div>
              {categories.length > 0 && (
                <div className="flex gap-8 items-center mb-3">
                  <label htmlFor="name" className="w-1/3 md:w-1/4">
                    نوع محصول
                  </label>
                  <Select
                    className="ring-1 ring-inset ring-gray-300 w-2/3 md:w-2/4"
                    placeholder="انتخاب"
                    onChange={(e) => {
                      setFormData((prevValue) => ({
                        ...prevValue,
                        product_category_id: e.value,
                      }));
                      setTmpCodes(() =>
                        codes.filter(
                          (row) => row.product_category_id === e.value
                        )
                      );
                    }}
                    options={categories}
                  />
                </div>
              )}
              {codes.length > 0 && (
                <div className="flex gap-8 items-center mb-3">
                  <label htmlFor="name" className="w-1/3 md:w-1/4">
                    کد
                  </label>
                  <Select
                    className="ring-1 ring-inset ring-gray-300 w-2/3 md:w-2/4"
                    placeholder="انتخاب"
                    onChange={(e) =>
                      setFormData((prevValue) => ({
                        ...prevValue,
                        product_code_id: e.value,
                      }))
                    }
                    options={tmpCodes}
                  />
                </div>
              )}
              {colors.length > 0 && (
                <div className="flex gap-8 items-center mb-3">
                  <label htmlFor="name" className="w-1/3 md:w-1/4">
                    رنگ
                  </label>
                  <Select
                    className="ring-1 ring-inset ring-gray-300 w-2/3 md:w-2/4"
                    placeholder="انتخاب"
                    onChange={(e) =>
                      setFormData((prevValue) => ({
                        ...prevValue,
                        color_id: e.value,
                      }))
                    }
                    options={colors}
                  />
                </div>
              )}
              <div className="flex gap-8 items-center mb-3">
                <label htmlFor="name" className="w-1/3 md:w-1/4">
                  ثبت گدام
                </label>
                <div className="flex gap-8 justify-start  w-2/3 md:w-2/4">
                  <label className="flex gap-2 border rounded-md py-1 px-4">
                    <input
                      type="radio"
                      name="stocked"
                      value="true"
                      onChange={(e) =>
                        setFormData((prev) => ({
                          ...prev,
                          stocked: e.target.value,
                        }))
                      }
                    />
                    بلی
                  </label>
                  <label className="flex gap-2 border rounded-md py-1 px-4">
                    <input
                      type="radio"
                      name="stocked"
                      value="false"
                      onChange={(e) =>
                        setFormData((prev) => ({
                          ...prev,
                          stocked: e.target.value,
                        }))
                      }
                    />
                    نخیر
                  </label>
                </div>
              </div>
              <div className="flex gap-8 items-center mb-3">
                <label htmlFor="name" className="w-1/3 md:w-1/4">
                  از بابت تسویه فیصدی خیاط
                </label>
                <div className="flex gap-8 justify-start  w-2/3 md:w-2/4">
                  <label className="flex gap-2 border rounded-md py-1 px-4">
                    <input
                      type="radio"
                      name="paid"
                      value="true"
                      onChange={(e) =>
                        setFormData((prev) => ({
                          ...prev,
                          paid: e.target.value,
                        }))
                      }
                    />
                    پرداخت شد
                  </label>
                  <label className="flex gap-2 border rounded-md py-1 px-4">
                    <input
                      type="radio"
                      name="paid"
                      value="false"
                      onChange={(e) =>
                        setFormData((prev) => ({
                          ...prev,
                          paid: e.target.value,
                        }))
                      }
                    />
                    باقی
                  </label>
                </div>
              </div>
              <div className="flex gap-8 items-center mb-3">
                <label htmlFor="bill_number" className="w-1/3 md:w-1/4">
                  بل نمبر
                </label>
                <input
                  name="bill_number"
                  type="text"
                  onChange={(e) =>
                    setFormData((prevValue) => ({
                      ...prevValue,
                      bill_number: e.target.value,
                    }))
                  }
                  value={formData.bill_number}
                  className="ring-1 ring-inset ring-gray-300  p-2 rounded-sm w-2/3 md:w-2/4"
                />
              </div>
            </form>

            <div className="flex  py-2 px-4 bg-gray-50 border-t justify-end">
              <button
                data-modal-hide="popup-modal"
                form="createPurchaseForm"
                type="subbmit"
                className="flex text-white bg-sky-500 hover:bg-sky-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 py-1 px-4 rounded-sm"
                disabled={loading ? true : false}
              >
                {loading ? (
                  <AiOutlineLoading3Quarters className="mt-1 mx-2 animate-spin" />
                ) : (
                  <FaDownload className="mt-1 mx-2" size={16} />
                )}
                تهیه گذارش
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReportDistribute;
