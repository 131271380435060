import React, { useEffect, useState } from "react";
import { IoMdAdd, IoMdArrowBack, IoMdCloudUpload } from "react-icons/io";
import { FaCheck, FaDownload, FaTrashAlt } from "react-icons/fa";
import axios from "../../lib/axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import toast from "react-hot-toast";
import { digitsFaToEn } from "@persian-tools/persian-tools";
import Select from "react-select";
import { toEn } from "../../lib/toEn";
import { useRef } from "react";
import { setImg } from "../../redux/features/imgSlice";
import { useDispatch, useSelector } from "react-redux";
import DocPreview from "../../components/purchases/DocPreview";
import Options from "../../components/distributes/Options";
import Delete from "../../components/Delete";
import Create from "../../components/distributes/payments/Create";
import List from "../../components/distributes/payments/List";
import Edit from "../../components/distributes/payments/Edit";
import { showPaymentCreateForm } from "../../redux/features/distributeSlice";

function DistributePayment() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const img = useSelector((state) => state.img.url);
  const deleteItem = useSelector((state) => state.delete.item);
  const paymentCreateForm = useSelector(
    (state) => state.distribute.paymentCreateForm
  );
  const paymentEditObj = useSelector(
    (state) => state.distribute.paymentEditObj
  );
  const [distribute, setDistribute] = useState(null);
  const [balance, setBalance] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!paymentCreateForm && !paymentEditObj && !deleteItem.id) {
      getDistribute();
    }
  }, [id, paymentCreateForm, paymentEditObj, deleteItem]);

  const getDistribute = () => {
    axios
      .get(`/api/distribute/${id}/payments`)
      .then((data) => {
        setDistribute(data);
        setBalance(
          (data.qtys * data.item.tailor_qty_percent -
            data.paid_tailor_fee_amount) *
            data.item.preparation.currency_rate
        );
      })
      .catch((error) => {
        toast.error(error.message);
        console.log(error);
      });
  };

  return (
    <div>
      <div className="flex bg-slate-50 border justify-between py-2 px-4">
        <h3>ثبت پرداخت فیس خیاط</h3>
        <div className="flex gap-6 ml-32">
          <Options distribute={distribute} edit={true} />
          {balance > 0 && (
            <button
              onClick={() => dispatch(showPaymentCreateForm())}
              className="bg-green-500 text-white border rounded-sm py-1 px-3 hover:bg-green-700"
            >
              ثبت پرداخت
            </button>
          )}
        </div>
      </div>
      <div className="p-4">
        {distribute?.id && (
          <div className="flex flex-col lg:flex-row gap-6">
            <div className="w-full p-6 border rounded-md  ">
              <div className=" flex flex-col lg:flex-row gap-8">
                <table className="w-full">
                  <thead>
                    <th className="border p-1">نمبر توزیع</th>
                    <th className="border p-1">گروپ (تیم خیاطی)</th>
                    <th className="border p-1">نمبر تهیه</th>
                    <th className="border p-1">کد</th>
                    <th className="border p-1">نوع محصول</th>
                    <th className="border p-1">رنگ</th>
                    <th className="border p-1">تاریخ توزیع</th>
                    <th className="border p-1">بیلانس</th>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="border p-1">{distribute.id}</td>
                      <td className="border p-1">
                        {distribute.tailor_team.name}
                      </td>
                      <td className="border p-1">
                        {distribute.item.preparation_id}
                      </td>
                      <td className="border p-1">
                        {distribute.item.product.code.code}
                      </td>
                      <td className="border p-1">
                        {distribute.item.product.code.category.name}
                      </td>
                      <td className="border p-1">
                        {distribute.item.color.name}
                      </td>
                      <td className="border p-1">
                        {distribute.distributed_date}
                      </td>
                      <td
                        className={`border p-1 ${
                          balance > 0.1 ? "bg-red-500" : "bg-green-500"
                        } text-white text-center`}
                      >
                        {balance < 0.1 ? 0 : Math.round(balance)} افغانی
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <hr className="my-8" />
              <List
                payments={distribute.payments}
                currency_rate={distribute.item.preparation.currency_rate}
              />
            </div>
          </div>
        )}
      </div>
      {img && <DocPreview />}
      {deleteItem.id && <Delete />}
      {paymentCreateForm && <Create distribute={distribute} />}
      {paymentEditObj && <Edit />}
    </div>
  );
}

export default DistributePayment;
