import React, { useEffect, useState } from "react";
import Material from "./Material";
import { TbIndentDecrease } from "react-icons/tb";
import { toEn } from "../../lib/toEn";
import Select from "react-select";
import axios from "../../lib/axios";
import toast from "react-hot-toast";

function Item({
  item,
  setItems,
  finalized,
  currency_rate,
  currency_rate_yuan,
}) {
  const [formData, setFormData] = useState(item);
  const [colors, setColors] = useState([]);
  const [loading, setLoading] = useState([]);

  useEffect(() => {
    getColors();
  }, []);

  const getColors = () => {
    axios.get(`/api/settings/colors/select2`).then((data) => {
      setColors(data);
    });
  };

  const handleChangeItem = (e, index) => {
    const value = toEn(e);

    const regex = /^[0-9]*(\.[0-9]{0,3})?$/;
    if (regex.test(value)) {
      setFormData((prev) => ({
        ...prev,
        [index]: index === "tailor_qty_percent" ? value / currency_rate : value,
      }));
    }
  };

  const submitForm = (e) => {
    e.preventDefault();

    setLoading(true);

    axios
      .put(`/api/preparation-item/${item.id}/update`, { ...formData })
      .then((data) => {
        if (data.status === 409) {
          toast.error("ریکارد با ان مشخصه موجود است.");
        } else {
          setFormData((prv) => ({
            ...prv,
            material_qty_price: data.material_qty_price,
            misalleneouse_qty_price: data.misalleneouse_qty_price,
            tailor_qty_percent: data.tailor_qty_percent,
            total_qty_price: data.total_qty_price,
          }));
          toast.success("عملیات موفقانه انجام شد.");
          updateItems();
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        const { response } = error;
        if (response.status === 422) {
          toast.error(response.data.message);
        } else {
          toast.error(error.message);
        }
      });
  };

  const updateItems = () => {
    setItems((prv) => {
      const tmp = prv.map((row, index) => {
        if (row.id === item.id) {
          return formData;
        }

        return row;
      });
      return tmp;
    });
  };

  return (
    <div className="p-4">
      <div className="flex justify-between text-gray-500 ">
        <table>
          <tr>
            <th className="bg-gray-50 border w-44">قیمت اولیه فی واحد</th>
            <td className="bg-gray-50 border w-60 p-2">
              <input
                name="material_qty_price"
                type="number"
                step={0.0}
                min={0.0}
                form="itemForm"
                disabled
                value={formData.material_qty_price}
                className="ring-1 ring-inset ring-gray-300  p-1 rounded-sm w-full"
              />
            </td>
            <td className="bg-gray-50 border p-2">دالر</td>
          </tr>
          <tr>
            <th className=" border w-44">
              متفرقه فی واحد <span className="text-red-500">*</span>
            </th>
            <td className=" border w-60 p-2">
              <input
                name="misalleneouse_qty_price"
                type="text"
                required
                form="itemForm"
                value={formData.misalleneouse_qty_price}
                onChange={(e) => handleChangeItem(e, "misalleneouse_qty_price")}
                className="ring-1 ring-inset ring-gray-300  p-1 rounded-sm w-full"
              />
            </td>
            <td className=" border p-2">دالر</td>
          </tr>
          <tr>
            <th className="bg-gray-50 border w-44">
              فیصدی خیاط فی واحد <span className="text-red-500">*</span>
            </th>
            <td className="bg-gray-50 border w-60 p-2">
              <input
                name="tailor_qty_percent"
                type="text"
                required
                form="itemForm"
                disabled
                value={formData.tailor_qty_percent}
                className="ring-1 ring-inset ring-gray-300  p-1 rounded-sm w-full"
              />
            </td>
            <td className="bg-gray-50 border p-2">دالر</td>
            <td className="bg-gray-50 border w-60 p-2">
              <input
                name="tailor_qty_percent"
                type="text"
                required
                form="itemForm"
                step={0}
                value={Math.round(formData.tailor_qty_percent * currency_rate)}
                onChange={(e) => handleChangeItem(e, "tailor_qty_percent")}
                className="ring-1 ring-inset ring-gray-300  p-1 rounded-sm w-full"
              />
            </td>
            <td className="bg-gray-50 border p-2">افغانی</td>
          </tr>
          <tr>
            <th className=" border w-44">مجموع فی واحد</th>
            <td className=" border w-60 p-2">
              <input
                name="total_qty_price"
                type="number"
                step={0.0}
                min={0.0}
                form="itemForm"
                disabled
                value={formData.total_qty_price}
                className="ring-1 ring-inset ring-gray-300  p-1 rounded-sm w-full"
              />
            </td>
            <td className=" border p-2">دالر</td>
          </tr>
        </table>
      </div>
      <form
        method="post"
        id="itemForm"
        onSubmit={submitForm}
        className=" mt-4 mb-12 text-gray-500"
      >
        <table className="table-auto w-full">
          <thead>
            <tr>
              <th className="px-4 py-2 text-start border">
                رنگ <span className="text-red-500">*</span>
              </th>

              <th className="px-4 py-2 text-start border">
                تعداد <span className="text-red-500">*</span>
              </th>
              <th className="px-4 py-2 text-start border"></th>
            </tr>
          </thead>
          <tbody>
            <tr className="bg-gray-50">
              <td className=" px-4 py-2 border align-top ">
                {colors && (
                  <Select
                    className="ring-1 ring-inset ring-gray-300 "
                    placeholder="انتخاب"
                    form="itemForm"
                    required
                    onChange={(e) =>
                      setFormData((prevValue) => ({
                        ...prevValue,
                        color_id: e.value,
                      }))
                    }
                    options={colors}
                    value={
                      formData.color_id
                        ? colors.find((row) => row.value === formData.color_id)
                        : null
                    }
                  />
                )}
              </td>

              <td className=" px-4 py-2 border align-top ">
                <input
                  name="qtys"
                  type="text"
                  required={true}
                  value={formData.qtys}
                  form="itemForm"
                  onChange={(e) => handleChangeItem(e, "qtys")}
                  className="flex items-start ring-1 ring-inset ring-gray-300  p-1 rounded-sm w-full"
                />
              </td>
              <td className=" px-4 py-2 border align-top ">
                {!finalized && (
                  <button className="bg-green-500 rounded-sm px-2 py-1 text-white hover:bg-green-700 ">
                    ذخیره گردده
                  </button>
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </form>

      <h3 className="flex gap-1">
        <TbIndentDecrease size={24} /> برداشت از گدام
      </h3>
      <hr />
      <Material
        id={item.id}
        setItem={setFormData}
        finalized={finalized}
        currency_rate={currency_rate}
        currency_rate_yuan={currency_rate_yuan}
      />
    </div>
  );
}

export default Item;
